import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ExpandablePanel, Accordion, AccordionItem, BaseExpand, ExpandableText, ExpandableTextButton, ExpandArrow } from '@entur/expand';
import { Heading2, Heading3, Paragraph } from '@entur/typography';
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<ExpandablePanel title=\"Kollektivreisende\">\n  <Heading3>\n    Planlegg hele reisen fra ett sted — uansett hvor du skal\n  </Heading3>\n  <Paragraph>\n    For de reisende tilbyr Entur en nasjonal reiseplanlegger, der man får\n    oppdaterte reiseforslag for alle kollektivreiser i Norge. Denne løsningen\n    er gratis å bruke og konkurransenøytral.\n  </Paragraph>\n  <Heading3>Én billett til hele reisen — på tvers av selskaper</Heading3>\n  <Paragraph>\n    Uansett om reisen består av flere delstrekninger, vil Entur gjøre det\n    mulig å kjøpe billetter til hele reisen med ett tastetrykk. Dette gjelder\n    selv om man må bytte mellom tog og buss, eller reise på tvers av\n    fylkesgrenser.\n  </Paragraph>\n\n  <Heading3>Kollektivdata til alle — mulighetene er mange</Heading3>\n  <Paragraph>\n    I fremtiden vil flere og flere tilby reiseinformasjon som en del av sine\n    tjenester. Blant annet bruker Google, Finn, Gule Sider og Bing data fra\n    oss i dag.\n  </Paragraph>\n</ExpandablePanel>'} __scope={{
      props,
      DefaultLayout,
      ExpandablePanel,
      Accordion,
      AccordionItem,
      BaseExpand,
      ExpandableText,
      ExpandableTextButton,
      ExpandArrow,
      Heading2,
      Heading3,
      Paragraph,
      Props,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ExpandablePanel title="Kollektivreisende" mdxType="ExpandablePanel">
    <Heading3 mdxType="Heading3">
      Planlegg hele reisen fra ett sted — uansett hvor du skal
    </Heading3>
    <Paragraph mdxType="Paragraph">
      For de reisende tilbyr Entur en nasjonal reiseplanlegger, der man får oppdaterte reiseforslag for alle kollektivreiser i Norge. Denne løsningen er gratis å bruke og konkurransenøytral.
    </Paragraph>
        <Heading3 mdxType="Heading3">Én billett til hele reisen — på tvers av selskaper</Heading3>
        <Paragraph mdxType="Paragraph">
  Uansett om reisen består av flere delstrekninger, vil Entur gjøre det mulig å kjøpe billetter til hele reisen med ett tastetrykk. Dette gjelder selv om man må bytte mellom tog og buss, eller reise på tvers av fylkesgrenser.
        </Paragraph>

        <Heading3 mdxType="Heading3">Kollektivdata til alle — mulighetene er mange</Heading3>
        <Paragraph mdxType="Paragraph">
  I fremtiden vil flere og flere tilby reiseinformasjon som en del av sine tjenester. Blant annet bruker Google, Finn, Gule Sider og Bing data fra oss i dag.
        </Paragraph>
  </ExpandablePanel>
    </Playground>
    <h2 {...{
      "id": "bruk"
    }}>{`Bruk`}</h2>
    <h3 {...{
      "id": "expandablepanel"
    }}>{`ExpandablePanel`}</h3>
    <ImportStatement imports="ExpandablePanel" mdxType="ImportStatement" />
    <p><inlineCode parentName="p">{`ExpandablePanel`}</inlineCode>{` kan brukes om man har noe innhold man vil skjule initielt, men som skal kunne åpnes og lukkes ved klikk.
ExpandablePanel skal i utgangspunktet ikke stå alene, dvs, det burde være minst `}<em parentName="p">{`to`}</em>{` ExpandablePanel ved siden av hverandre til ei hver tid.
ExpandableText skal heller brukes om man har et enkeltstående innhold man ønsker å skjule.`}</p>
    <p>{`Om man har lyst til å kontrollere åpningen og lukkingen selv, så kan man benytte seg av `}<inlineCode parentName="p">{`open`}</inlineCode>{` og `}<inlineCode parentName="p">{`onToggle`}</inlineCode>{`.`}</p>
    <p>{`Her er et eksempel:`}</p>
    <Playground __position={3} __code={'() => {\n  const [open, setOpen] = React.useState(false)\n  return (\n    <ExpandablePanel\n      title=\"Om Entur\"\n      open={open}\n      onToggle={() => setOpen(!open)}\n    >\n      I Norge har vi et godt kollektivtilbud. Vi tror at enda flere vil velge\n      å reise kollektivt om det blir enklere å finne frem i tilbudet. Derfor\n      samler vi reisedata fra alle kollektivselskap i landet.\n    </ExpandablePanel>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ExpandablePanel,
      Accordion,
      AccordionItem,
      BaseExpand,
      ExpandableText,
      ExpandableTextButton,
      ExpandArrow,
      Heading2,
      Heading3,
      Paragraph,
      Props,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [open, setOpen] = React.useState(false);
        return <ExpandablePanel title="Om Entur" open={open} onToggle={() => setOpen(!open)} mdxType="ExpandablePanel">
        I Norge har vi et godt kollektivtilbud. Vi tror at enda flere vil velge
        å reise kollektivt om det blir enklere å finne frem i tilbudet. Derfor
        samler vi reisedata fra alle kollektivselskap i landet.
      </ExpandablePanel>;
      }}
    </Playground>
    <h3 {...{
      "id": "expandabletext"
    }}>{`ExpandableText`}</h3>
    <p>{`ExpandableText brukes om man ønsker å skjule et innhold, og i motsetning til ExpandablePanel, så står den gjerne alene uten andre "Expand"-komponenter.`}</p>
    <Playground __position={4} __code={'<ExpandableText title=\"Se mer info\">\n  Her står det mye mer info, under en mindre knapp som ikke tar så mye fokus\n  fra brukeren, men fortsatt har muligheten til åpning/lukking. Her står det\n  mye mer info, under en mindre knapp som ikke tar så mye fokus fra brukeren,\n  men fortsatt har muligheten til åpning/lukking.\n</ExpandableText>'} __scope={{
      props,
      DefaultLayout,
      ExpandablePanel,
      Accordion,
      AccordionItem,
      BaseExpand,
      ExpandableText,
      ExpandableTextButton,
      ExpandArrow,
      Heading2,
      Heading3,
      Paragraph,
      Props,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} style={{
      alignItems: 'start'
    }} mdxType="Playground">
  <ExpandableText title="Se mer info" mdxType="ExpandableText">
    Her står det mye mer info, under en mindre knapp som ikke tar så mye fokus
    fra brukeren, men fortsatt har muligheten til åpning/lukking. Her står det
    mye mer info, under en mindre knapp som ikke tar så mye fokus fra brukeren,
    men fortsatt har muligheten til åpning/lukking.
  </ExpandableText>
    </Playground>
    <h3 {...{
      "id": "accordion-og-accordionitem"
    }}>{`Accordion og AccordionItem`}</h3>
    <ImportStatement imports="Accordion, AccordionItem" mdxType="ImportStatement" />
    <p>{`Om du ønsker å gruppere flere `}<inlineCode parentName="p">{`ExpandablePanel`}</inlineCode>{`-komponenter sammen til en accordion, kan du heller bruke `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{`- og `}<inlineCode parentName="p">{`AccordionItem`}</inlineCode>{`-komponenter:`}</p>
    <Playground __position={6} __code={'<Accordion>\n  <AccordionItem title=\"Kan jeg kjøpe billetter i Norden?\" defaultOpen>\n    Billetter til Gøteborg, Åre og Østersund kan bestilles på vy.no. Ønsker du\n    å reise til Stockholm, København eller andre reisemål i Europa kan\n    kundesenteret vårt hjelpe deg med informasjon og bestilling av billett.\n    Les mer om togreiser i utlandet .\n  </AccordionItem>\n  <AccordionItem title=\"Kan jeg kjøpe Interrail-billett i app?\">\n    Vi selger dessverre ikke Interrail-billetter i appen vår. Billetter til\n    Interrail kan du bestille på telefon via kundesenteret eller hos betjente\n    togstasjoner. Det er en bookingavgift på 195 kr pr. Interrail-billett ved\n    kjøp i Norge. Ønsker du å bestille på en ekstern nettside kan\n    europarundt.no være et alternativ. Husk å ha passnummer tilgjengelig..\n  </AccordionItem>\n  <AccordionItem title=\"Hvordan kan jeg refundere billetter?\">\n    Alle billetter som ikke har startet allerede kan refunderes innen 4 timer\n    fra de ble kjøpt, da vi tilbyr angrefrist på alle kjøp. Utover 4 timer\n    kommer det an på hvilken billettype du har kjøpt om billetten kan\n    refunderes. Detaljerte refusjonsregler for hver billettype finner du\n    vanligvis på operatørens nettsider.\n    <br />\n    Refunderbare billetter kan du selv avbestille i appen ved å åpne billetten\n    du ønsker å avbestille, som du finner under «Billetter». Videre klikker du\n    på «Avbestill billett» og bekrefter avbestilling. Billetten er nå\n    avbestilt og eventuell refusjon blir automatisk refundert.\n  </AccordionItem>\n</Accordion>'} __scope={{
      props,
      DefaultLayout,
      ExpandablePanel,
      Accordion,
      AccordionItem,
      BaseExpand,
      ExpandableText,
      ExpandableTextButton,
      ExpandArrow,
      Heading2,
      Heading3,
      Paragraph,
      Props,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Accordion mdxType="Accordion">
    <AccordionItem title="Kan jeg kjøpe billetter i Norden?" defaultOpen mdxType="AccordionItem">
      Billetter til Gøteborg, Åre og Østersund kan bestilles på vy.no. Ønsker du
      å reise til Stockholm, København eller andre reisemål i Europa kan
      kundesenteret vårt hjelpe deg med informasjon og bestilling av billett.
      Les mer om togreiser i utlandet .
    </AccordionItem>
    <AccordionItem title="Kan jeg kjøpe Interrail-billett i app?" mdxType="AccordionItem">
      Vi selger dessverre ikke Interrail-billetter i appen vår. Billetter til
      Interrail kan du bestille på telefon via kundesenteret eller hos betjente
      togstasjoner. Det er en bookingavgift på 195 kr pr. Interrail-billett ved
      kjøp i Norge. Ønsker du å bestille på en ekstern nettside kan
      europarundt.no være et alternativ. Husk å ha passnummer tilgjengelig..
    </AccordionItem>
    <AccordionItem title="Hvordan kan jeg refundere billetter?" mdxType="AccordionItem">
      Alle billetter som ikke har startet allerede kan refunderes innen 4 timer
      fra de ble kjøpt, da vi tilbyr angrefrist på alle kjøp. Utover 4 timer
      kommer det an på hvilken billettype du har kjøpt om billetten kan
      refunderes. Detaljerte refusjonsregler for hver billettype finner du
      vanligvis på operatørens nettsider.
      <br />
      Refunderbare billetter kan du selv avbestille i appen ved å åpne billetten
      du ønsker å avbestille, som du finner under «Billetter». Videre klikker du
      på «Avbestill billett» og bekrefter avbestilling. Billetten er nå
      avbestilt og eventuell refusjon blir automatisk refundert.
    </AccordionItem>
  </Accordion>
    </Playground>
    <h3 {...{
      "id": "expandabletextbutton"
    }}>{`ExpandableTextButton`}</h3>
    <ImportStatement imports="ExpandableTextButton" mdxType="ImportStatement" />
    <h3 {...{
      "id": "baseexpand"
    }}>{`BaseExpand`}</h3>
    <ImportStatement imports="BaseExpand" mdxType="ImportStatement" />
    <p>{`ExpandablePanel, AccordionItem (og andre komponenter i designsystemet) benytter seg av `}<inlineCode parentName="p">{`<BaseExpand>`}</inlineCode>{`, så om man har behov for å benytte seg av åpne/lukkemekanismen direkte, kan man benytte seg av denne.`}</p>
    <h3 {...{
      "id": "expandarrow"
    }}>{`ExpandArrow`}</h3>
    <ImportStatement imports="ExpandArrow" mdxType="ImportStatement" />
    <p>{`Hvis man bruker `}<inlineCode parentName="p">{`BaseExpand`}</inlineCode>{`, så kan man bruke `}<inlineCode parentName="p">{`ExpandArrow`}</inlineCode>{` samtidig for å få den roterende pila som brukes i alle expandable-komponenter i designsystemet.`}</p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Expandables lar brukerne ekspandere og kollapse innhold på en side. Pil-ikonet brukes til å indikere ekspandere/kollapse handlingen,
men hele headeren er klikkbar for samme handling. Komponenten gir oss en måte å presentere en stor mengde informasjon,
samtidig som brukerne får bestemme over hvor mye de vil se. Den hjelper oss også med å organisere informasjon for å holde grensesnittet
ryddig og oversiktlig, slik at brukerne finner det innholdet de trenger.`}</p>
    <p>{`Expandables er fin å bruke hvis du har en liten plass til å vise mye innhold, for eksempel på en mobil.
Ved å bruke expandables kan man også redusere scrolling på en side.`}</p>
    <h3 {...{
      "id": "varianter"
    }}>{`Varianter`}</h3>
    <h4 {...{
      "id": "expandablepanel-1"
    }}>{`ExpandablePanel`}</h4>
    <p>{`ExpansionPanel bruker man hvis det er behov for å se flere paneler oppe om gangen. En ExpansionPanel kan også inneholde flere nivåer/levels. ExpandablePanel skal i utgangspunktet ikke stå alene, og det bør være minst to ExpandablePanels ved siden av hverandre til en hver tid. Bruk heller ExpandableText om man har et enkeltstående innhold man ønsker å skjule.For å kontrollere åpning og lukking selv, så kan man benytte seg av open og onToggle.`}</p>
    <h4 {...{
      "id": "expandabletext-1"
    }}>{`ExpandableText`}</h4>
    <p>{`ExpandableText brukes om man ønsker å skjule et innhold. I motsetning til ExpandablePanel så står den gjerne alene uten andre ‘expand’-komponenter.`}</p>
    <h4 {...{
      "id": "accordion-og-accordionitem-1"
    }}>{`Accordion og AccordionItem`}</h4>
    <p>{`Accordion er ett sett av collapsible, hvor man ekspanderer en og en panel om gangen. Om du ønsker å gruppere flere ExpandablePanel-komponenter sammen til en accordion, kan du heller bruke Accordion- og AccordionItem-komponenter:`}</p>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "expandablepanel-2"
    }}>{`ExpandablePanel`}</h3>
    <ImportStatement imports="ExpandablePanel" mdxType="ImportStatement" />
    <Props of={ExpandablePanel} mdxType="Props" />
    <h3 {...{
      "id": "expandabletext-2"
    }}>{`ExpandableText`}</h3>
    <ImportStatement imports="ExpandableText" mdxType="ImportStatement" />
    <Props of={ExpandableText} mdxType="Props" />
    <h3 {...{
      "id": "accordion-og-accordionitem-2"
    }}>{`Accordion og AccordionItem`}</h3>
    <ImportStatement imports="Accordion, AccordionItem" mdxType="ImportStatement" />
    <Props of={Accordion} title="Props, Accordion" mdxType="Props" />
    <Props of={AccordionItem} title="Props, AccordionItem" mdxType="Props" />
    <h3 {...{
      "id": "expandabletextbutton-1"
    }}>{`ExpandableTextButton`}</h3>
    <ImportStatement imports="ExpandableTextButton" mdxType="ImportStatement" />
    <Props of={ExpandableTextButton} mdxType="Props" />
    <h3 {...{
      "id": "baseexpand-1"
    }}>{`BaseExpand`}</h3>
    <ImportStatement imports="BaseExpand" mdxType="ImportStatement" />
    <Props of={BaseExpand} mdxType="Props" />
    <h3 {...{
      "id": "expandarrow-1"
    }}>{`ExpandArrow`}</h3>
    <ImportStatement imports="ExpandArrow" mdxType="ImportStatement" />
    <Props of={ExpandArrow} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      